import React from "react"
import { navigate } from "gatsby"

import { getToken } from "../http/client"

function Auth(WrappedComponent) {
  return props => {
    const accessToken = getToken()

    if (!accessToken) {
      // navigate("/signin")
      return null
    } else {
      return <WrappedComponent {...props} />
    }
  }
}

export default Auth
